<template>
  <div class="item-skeleton">
    <div>
      <div class="item-header-section">
        <div class="item-left-header">
          <h4 class="hlt-header h4 weight-700">Item ID {{ asset.id }}</h4>
          <span class="question-type">{{ asset.pretty_type }}</span>
        </div>
        <div v-if="showAssignmnetMeta" class="assignment-meta">
          <span>Assigned To: {{ assignment.assigned_to_admin_user.full_name }}</span>
          <span>Updated At: {{ prettyDate(assignment.updated_at) }}</span>
        </div>
      </div>
    </div>

    <stem-stimulus
      v-if="asset.type !== 'Flipcard'"
      :asset="asset"
    />
    <div style="outline: 2px solid #FCDF5F;">
      <label class="weight-700">Draft Stem</label>
      <tinymce-editor
        ref="stem-editor"
        v-model="asset.draft_question"
        :init="tinymceConfig({plugins: ['code', 'image', 'table', 'lists', 'autoresize'], autoresize_bottom_margin: 10}, tinymceFullConfig)"
        use-custom-image-handler
        @onInit="setCaretPosition"
      />
     </div>
     
    <explanation
      v-if="asset.type !== 'Flipcard'"
      :asset="asset"
    />

    <div style="outline: 2px solid #FCDF5F;">
      <label class="weight-700">Draft Explanation</label>
      <tinymce-editor
        ref="explanation-editor"
        v-model="asset.draft_rationale"
        :init="tinymceConfig({plugins: ['code', 'image', 'table', 'lists', 'autoresize'], autoresize_bottom_margin: 10}, tinymceFullConfig)"
        use-custom-image-handler
        @onInit="setCaretPosition"
      />
     </div>

     <br/>
    <simple-text-box
      title="Key Takeaway"
      elm-name="key_takeaway"
      :asset="asset"
      :showCharCount="true"
    />
    <simple-text-box
      title="Draft Key Takeaway"
      elm-name="draft_key_takeaway"
      :asset="asset"
      :showCharCount="true"
      style="outline: 2px solid #FCDF5F;"
    />

    <simple-text-box
      title="Exam Tip"
      elm-name="exam_tip"
      :asset="asset"
      :showCharCount="true"
    />
     
    <simple-text-box
      title="Draft Exam Tip"
      elm-name="draft_exam_tip"
      :asset="asset"
      :showCharCount="true"
      style="outline: 2px solid #FCDF5F;"
    />




    <drag-and-drop v-if="asset.type === 'DragAndDrop'" />
    <multiple-choice v-if="asset.type === 'MultipleChoice' || asset.type === 'Sata'" />
    <blank-text-completion v-if="asset.type === 'BlankTextCompletion'" />
    <fractional-numeric-entry v-if="asset.type === 'FractionalNumericEntry'" />
    <numeric-entry v-if="asset.type === 'NumericEntry'" />
    <ordered-response v-if="asset.type === 'OrderedResponse'" />
    <flipcard v-if="asset.type === 'Flipcard'" />
    <date-picker v-if="asset.type === 'DatePicker'" />
    <matrix-grid v-if="asset.type === 'MatrixGrid'" />
    <hotspot v-if="asset.type === 'HotspotItem'" />
    <cloze v-if="asset.type === 'Cloze'" />
    <bowtie v-if="asset.type === 'Bowtie'" />
    <highlight v-if="asset.type === 'HighlightItem'" />
  </div>
</template>

<script>
import BlankTextCompletion from './item_types/BlankTextCompletion.vue'
import Bowtie from './item_types/Bowtie.vue'
import Cloze from './item_types/Cloze.vue'
import DatePicker from './item_types/DatePicker.vue'
import DragAndDrop from './item_types/DragAndDrop.vue'
import Explanation from './item_types/shared/Explanation.vue'
import Flipcard from './item_types/Flipcard.vue'
import FractionalNumericEntry from './item_types/FractionalNumericEntry.vue'
import Highlight from './item_types/Highlight.vue'
import Hotspot from './item_types/Hotspot.vue'
import MatrixGrid from './item_types/MatrixGrid.vue'
import MultipleChoice from './item_types/MultipleChoice.vue'
import NumericEntry from './item_types/NumericEntry.vue'
import OrderedResponse from './item_types/OrderedResponse.vue'
import SimpleTextBox from './item_types/shared/SimpleTextBox.vue'
import StemStimulus from './item_types/shared/StemStimulus.vue'
import TinymceEditor from '@tinymce/tinymce-vue'
import tinymce from '@/mixins/tinymce.js'

import date from '@/mixins/date.js'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    BlankTextCompletion,
    Bowtie,
    Cloze,
    DatePicker,
    DragAndDrop,
    Explanation,
    Flipcard,
    FractionalNumericEntry,
    Highlight,
    Hotspot,
    MatrixGrid,
    MultipleChoice,
    NumericEntry,
    OrderedResponse,
    SimpleTextBox,
    StemStimulus,
    TinymceEditor,
  },
  mixins: [date, tinymce],
  computed: {
    ...mapGetters([
      'asset',
      'assignment',
      'currentUser'
    ]),
    showAssignmnetMeta () {
      if (this.assignment) {
        return this.currentUser.permissions.is_a_copy_editor
      }
      return false
    }
  },
  methods: {
    ...mapActions([
      'copyToClipboard'
    ]),
    host () {
      return location.host
    }
  }
}
</script>

<style lang="scss">
.item-skeleton {
  width: 100%;
  .item-header-section {
    display: flex;
    flex-direction: row;
    justify-content:  space-between;
    align-items: baseline;
    div {
      height: 50px;
    }

    .item-left-header {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      .question-type {
        margin-left: 12px;
        font-size: 14px;
        color: $space-gray;
      }
    }
    .assignment-meta {
      display: flex;
      align-content: center;
      color: $space-gray;
      span {
        margin-right: 20px;
        font-size: 14px;
      }
    }
  }
}

</style>
